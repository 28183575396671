import React, {useState, useEffect} from 'react'

import {useFormik} from 'formik'
import axios from "axios"
import RepresentativePartial from "./subcomponent/RepresentativePartial";

export default function ContactHomeBlock(props) {
    const header = props.header ?? "contact"
    const contactData = props.contactData ?? []

    const api = props.mailApiUrl ?? ""

    const errorMessage = "We are having Issues delivering your Message to us. If, after retry, this problem persists, please contact us directly by email (this message will disappear after 10 seconds, please wait)"
    const finalErrorMessage = "We are having Issues delivering your Message to us. Please contact us by mail instead"

    let [currentOpacity, setOpacity] = useState(100)

    let [mailWasSend, setMailWasSend] = useState(false)
    let [mailMessage, setMailMessage] = useState("")

    const [numberOfMessageTries, setNumberOfMessageTries] = useState(0);
    const [currentlySendingMessage, setCurrentlySendingMessage] = useState(false);
    const [possibleAnimationQueue, setPossibleAnimationQueue] = useState("");

    const formik = useFormik({
        initialValues: {
            name: "",
            email: '',
            message: ""
        },

        onSubmit: values => {
            if (mailWasSend) {
                return
            }

            setNumberOfMessageTries(numberOfMessageTries + 1)
            setCurrentlySendingMessage(true)
            const data = JSON.stringify( { data: values }, null, 0 )

            axios
                .post(api, btoa(data))
                .then((resp) => {
                    setOpacity(0)
                    const data = resp.data
                    const code = data.code

                    let message
                    let mailSend

                    if(code === 200) {
                        message = "Your message was send to us, thank you, we will be in Contact"
                        mailSend = true
                    } else {
                        message = errorMessage
                        mailSend = false
                    }

                    setMailMessage(message)
                    setMailWasSend(mailSend)
                    setCurrentlySendingMessage(false)
                })
                .catch((error) => {
                    setOpacity(0)
                    console.error(error)

                    const errorMessageToShow = mailMessage === errorMessage ? finalErrorMessage : errorMessage

                    setMailMessage(errorMessageToShow)
                    setMailWasSend(false)
                    setCurrentlySendingMessage(false)
                })
        },
    });

    useEffect(function () {
        if(mailMessage === errorMessage || mailMessage === finalErrorMessage) {
            setTimeout(() => {
                setOpacity(mailMessage === finalErrorMessage ? 0 : 100)
            }, 10000);
        }
    }, [mailMessage])

    useEffect(function () {
        setPossibleAnimationQueue(currentlySendingMessage ? "animate-pulse" : "")
    }, [currentlySendingMessage])

    function containerizeContact(contact) {
        return <RepresentativePartial img={contact.img} name={contact.name} title={contact.title} tel={contact.tel} mail={contact.mail} />
    }

    return (
        <div id={"contactHomeBlock"} className={"block-padding block-outer-margin"}>
            <h2 className={"text-primary header-m"}>
                {header}
            </h2>
            <div className={"h-full grid grid-cols-1 xl:grid-cols-2"}>
                <div>
                    {contactData.map(containerizeContact)}
                </div>
                <div className={"relative"}>
                    <form
                        className={"relative transition-opacity ease-in-out duration-700"}
                        style={{opacity: currentOpacity, zIndex: (currentOpacity - 60)}}
                        id={'contact-form'}
                        onSubmit={formik.handleSubmit}>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-x-10"}>
                            <label>
                                <p className={"content-m text-left text-white-900 ml-0"}>Name:</p>
                                <input className={"input-field"} required={true} type="text" name="name"
                                       value={formik.values.name} onChange={formik.handleChange}/>
                            </label>
                            <label>
                                <p className={"content-m text-left text-white-900 ml-0"}>Mail:</p>
                                <input className={"input-field"} required={true} type="email" name="email"
                                       value={formik.values.email} onChange={formik.handleChange}/>
                            </label>
                            <label className={"col-start-1 md:col-end-3"}>
                                <p className={"content-m text-left text-white-900 ml-0"}>Message:</p>
                                <textarea className={"w-full input-field h-80"} required={true} name="message"
                                          value={formik.values.message} onChange={formik.handleChange}/>
                            </label>
                        </div>
                        {mailWasSend
                            ? <div className={"mt-10 w-full button-m"}> <p>message send</p> </div>
                            : <button className={"mt-10 w-full button-m " + possibleAnimationQueue}> <input type="submit" value="Submit"/> </button>
                        }
                    </form>

                    <div
                        className={"absolute top-0 h-full w-full flex justify-center items-center transition-opacity ease-in-out delay-1000 duration-1000"}
                        style={{opacity: (100 - currentOpacity), zIndex: ((currentOpacity - 60) * -1)}}>
                        <p className={"text-cyan-400 content-l"}>{mailMessage}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from "react"

import {bgImgStyle} from "../../../utils";

export default function VisionBlock(props) {

    const header = props.header ?? "our vision"
    const content = props.content ?? ""

    const img = props.img ?? ""

    return (
        <div id={"visionBlock"} className={"block-outer-margin block-padding"}>
            <h2 className={"text-primary header-m"}>{header}</h2>
            <div className={"pt-16 grid grid-cols-1 lg:pl-24 lg:h-96 lg:flex"}>
                <div className={"h-96 w-full bg-cover bg-right-bottom lg:w-2/5 lg:h-full"}
                     style={bgImgStyle(img)}
                />

                <div className={"flex justify-center items-center w-full"}>
                    <p className={"content-m text-white-900 lg:w-3/5"}>
                        {content}
                    </p>
                </div>
            </div>
        </div>
    )
}
import React from "react";

export default function FooterView() {

    return(
        <div>
            <footer className={"bg-primary p-10 flex justify-end gap-5"}>
                <a href={"/?page=impressum"} className={"content-m m-0 p-0"}>Impressum</a>
                <a href={"/?page=privacy"} className={"content-m m-0 p-0"}>Privacy</a>
            </footer>
        </div>
    )
}
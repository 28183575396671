import React from "react";

export default function PrivacyView() {
    return (
        <div className={"bg-black-900 block-padding min-h-screen"}>
            <h1 className={"header-l text-white-900 mb-10"}>Privacy</h1>
            <div className={"content-m text-white-900 block-outer-margin text-left"}>
                <h3 className={"header-m text-left pb-10"}>Disclaimer</h3>

                <h4 className={"py-10 text-2xl"}>1. Content of the online offer </h4>

                <p className={"pl-6"}>The author assumes no liability for the topicality, correctness, completeness or quality of the information provided. Liability claims against the author relating to material or immaterial damage caused by the use or non-use of the information provided or by the use of incorrect or incomplete information are excluded in principle, unless the author can be proven to have acted intentionally or with gross negligence fault exists. All offers are non-binding. The author expressly reserves the right to change, supplement or delete parts of the pages or the entire offer without prior notice or to temporarily or permanently discontinue publication. </p>

                <h4 className={"py-10 text-2xl"}>2. References and Links</h4>

                <p className={"pl-6"}>In the case of direct or indirect references to external websites ("hyperlinks") that are outside the author's area of responsibility, liability would only come into effect if the author was aware of the content and it was technically possible and reasonable for him to do so to prevent the use of illegal content. </p>

                <p className={"pl-6"}>The author hereby expressly declares that no illegal content was discernible on the linked pages at the time the link was created. The author has no influence whatsoever on the current and future design, the content or the authorship of the linked/connected pages. He therefore hereby expressly distances himself from all content on all linked / connected pages that were changed after the link was created. This statement applies to all links and references set within our own website as well as to third-party entries in guest books, discussion forums, link directories, mailing lists and all other forms of databases set up by the author, the content of which can be accessed externally. The provider of the page to which reference was made is solely liable for illegal, incorrect or incomplete content and in particular for damage resulting from the use or non-use of such information, not the person who merely refers to the respective publication via links.</p>

                <h4 className={"py-10 text-2xl"}>3. Copyright and trademark law</h4>

                <p className={"pl-6"}>The author endeavors to observe the copyrights of the images, graphics, sound documents, video sequences and texts used in all publications, to use images, graphics, sound documents, video sequences and texts he has created himself or to use license-free graphics, sound documents, video sequences and texts.</p>

                <p className={"pl-6"}>All brands and trademarks mentioned within the website and possibly protected by third parties are subject without restriction to the provisions of the applicable trademark law and the property rights of the respective registered owner. The conclusion that trademarks are not protected by the rights of third parties should not be drawn solely on the basis of the mere mention!</p>

                <p className={"pl-6"}>The copyright for published objects created by the author himself remains solely with the author of the pages. Reproduction or use of such graphics, sound documents, video sequences and texts in other electronic or printed publications is not permitted without the express consent of the author. Should certain elements of my homepage infringe any copyright or injure persons, please notify me immediately so that the element in question can be removed. Anyone who takes legal action without notification is acting illegally against me (abuse of rights).</p>

                <h4 className={"py-10 text-2xl"}>4. Privacy</h4>

                <p className={"pl-6"}>If the opportunity for the input of personal or business data (email addresses, name, addresses) is given, the input of this data takes place on the part of the user on an expressly voluntary basis. The use and payment of all services offered is - as far as technically possible and reasonable - permitted without providing such data or by providing anonymous data or a pseudonym. The use of the contact data published in the imprint or comparable information such as postal addresses, telephone and fax numbers and email addresses by third parties to send information that has not been expressly requested is not permitted. We expressly reserve the right to take legal action against the senders of so-called spam mails in the event of violations of this prohibition.</p>

                <h4 className={"py-10 text-2xl"}>5. Validity of this disclaimer</h4>

                <p className={"pl-6"}>This disclaimer of liability is to be regarded as part of the website from which reference was made to this page. If parts or individual formulations of this text do not, no longer or not completely correspond to the applicable legal situation, the remaining parts of the document remain unaffected in their content and validity.</p>
            </div>
        </div>
    )
}
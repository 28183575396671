import React from "react";

export default function ImpressumView() {
    return (
        <div className={"bg-black-900 block-padding h-screen"}>
            <h1 className={"header-l text-white-900 mb-10"}>Impressum</h1>
            <div className={"content-m text-white-900 block-outer-margin text-left"}>
                <h3 className={"header-m text-left pb-10"}>Brunello Media Group GmbH</h3>

                <p>Hartholzstraße 12 | Top 2</p>
                <p>2640 Gloggnitz</p>
                <p>AUSTRIA</p>
                <p>office@brunellomediagroup.at</p>
                <p>ATU: 75947246</p>
                <p>FN:  541878f</p>
            </div>
        </div>
    )
}
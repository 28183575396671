import React, {useEffect, useState} from 'react'
import axios from "axios";
import Slider from "react-slick";

import backupImage from "../../../resources/imgs/Content Fotos Videos Mushroom/Shooting 2/brunello_7.JPG"
import {bgImgStyle} from "../../../utils";

export default function StudioPreviewBlock(props) {
    const header = props.header ?? "our studio"
    const slideshowApiUrl = props.slideshowApiUrl ?? ""

    const [content, setContent] = useState([ backupImage, backupImage, backupImage, backupImage, backupImage ])

    const settings = {
        lazyLoad: 'ondemand',

        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,

        arrows: false,

        centerMode: true,
        centerPadding: '50px',

        autoplay: true,
        autoplaySpeed: 7000,

        mobileFirst: true,
        touchMove: true,

        responsive: [
            {
                breakpoint: 1022,
                settings: {
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1,
                }
            }
        ]
    };

    useEffect(function () {
        axios.get(slideshowApiUrl)
            .then((resp) => {
                let data = resp.data
                setContent(data)
            }).catch((error) => {
                console.error(error)
            })
    }, [])

    function containerizeSliderImg(src) {
        return (
            <>
                <div className={"w-full h-96 bg-cover bg-center"} style={bgImgStyle(src)} />
            </>
        )
    }

    return (
        <div id={"studioPreviewBlock"} className={"block-padding"}>
            <h2 className={"text-primary header-m"}>
                {header}
            </h2>
            <div className={"p-12 relative"}>
                <div className={""/*"absolute h-full left-0 top-0 md:w-44 md:bg-gradient-to-r md:from-black-500 md:to-transparent md:z-10"*/} />
                <Slider {...settings}>
                    {content.map(containerizeSliderImg)}
                </Slider>
                <div className={""/*"absolute h-full right-0 top-0 md:w-44 md:bg-gradient-to-l md:from-black-500 md:to-transparent md:z-10"*/} />
            </div>
        </div>
    )
}
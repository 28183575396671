import React from "react"

export function renderBlock(block, customClass = "", pageRef = null) {
    return (
        <div className={customClass} ref={pageRef}>
            {block}
        </div>
    )
}

export function mailLink(mail) {
    return <a href={"mailto:" + mail}>{mail}</a>
}

export function telLink(tel) {
    return <a href={"tel:" + tel}>{tel}</a>
}

export function peeker(iterator) {
    let peeked = iterator.next();

    let rebuiltIterator = function*() {
        if(peeked.done)
            return;

        yield peeked.value;
        yield* iterator;
    }

    return { peeked, rebuiltIterator };
}

export function bgImgStyle(src) {
    return { backgroundImage: `url(` + src + `)` }
}

export function getCurrentRootUrl() {
    let full_url = window.location.href
    let root_url_pattern = /(http:\/\/).*?\//i
    let match = full_url.match(root_url_pattern)
    if (match == null) {
        let root_url_pattern = /(https:\/\/).*?\//i
        return full_url.match(root_url_pattern)[0]
    }
    return match[0]
}
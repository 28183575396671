import React from 'react'
import {mailLink, telLink} from "../../../../utils";

export default function RepresentativePartial(props) {

    const img = props.img ?? ""
    const name = props.name ?? ""
    const title = props.title ?? ""
    const tel = props.tel ?? ""
    const mail = props.mail ?? ""

    return (
        <div className={"pt-20 mx-auto gap-x-10 grid grid-cols-1 md:grid-cols-2"}>
            <div className={"relative"}>
                <img className={"xl:absolute right-0 w-full md:mr-auto md:ml-0 xl:w-auto xl:h-52"} src={img} alt={""} style={{filter: "grayscale(100%)"}}/>
            </div>
            <div className={"relative"}>
                <p className={"text-white-900 content-m text-left pt-0"}>{name}</p>
                <p className={"text-gray-500 content-m text-left pt-0 pb-6"}>{title}</p>
                <p className={"text-white-900 content-m text-left pt-0"}>{telLink(tel)}</p>
                <p className={"text-white-900 content-m text-left pt-0"}>{mailLink(mail)}</p>
            </div>
        </div>
    )
}
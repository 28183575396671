import React from "react"
import {bgImgStyle} from "../../../utils";
import LogoBanner from "./subcomponent/LogoBanner";

export default function HeadImgBlock(props) {

    const img = props.img
    const header = props.header ?? "pinkpoolmusic"

    // noinspection JSUnresolvedVariable
    const buttonText = props.buttonText ?? "dive right in"

    const logos = props.logos

    const executeScroll = props.buttonRef
        ? () => props.buttonRef.current.scrollIntoView({behavior: 'smooth'})
        : () => console.log("no reference set for reference button in head img block")

    return (
        <div className={"relative"}>
            <div id={"headImgBlock"}
                 style={bgImgStyle(img)}
                 className={'h-screen bg-cover bg-right flex justify-center items-center'} >

                <div>
                    <h1 className={"text-white-900 header-l"}>{header}</h1><br/>
                    <div className={"text-primary text-center font-recursive mt-24"}>
                        <button onClick={executeScroll} className={"button-m"}>
                            {buttonText}
                        </button>
                    </div>
                </div>
            </div>
            <div className={"lg:absolute bottom-0 w-full"}>
                <LogoBanner logos={logos} />
            </div>
        </div>
    )
}

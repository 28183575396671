import React from "react";
import {renderBlock} from "../../../../utils"
import {BgColorPattern} from "../../../../consts";

export default function HomepageView(props) {

    let patternIterator = BgColorPattern.values()

    const blocks = props.blocks ?? []

    function getNextPattern() {

        let nextPattern = patternIterator.next()

        // iterator needs to be rebuilt on empty next value
        patternIterator = nextPattern.done ? BgColorPattern.values() : patternIterator
        // new value needs to be pulled on reset of patternIterator
        nextPattern = nextPattern.done ? patternIterator.next() : nextPattern

        return nextPattern.value
    }

    function renderBlockWithBackgroundPattern(block) {
        let nextPattern = getNextPattern()
        let pageRef = block.props.pageRef
        return renderBlock(block, nextPattern, pageRef)
    }

    return (
        <>
            {blocks.map(renderBlockWithBackgroundPattern)}
        </>
    )
}
import React, {useState} from "react";

import BurgerIcon from '../../../resources/icons/pinkPoolBurgerIcon.svg'
import navCloseIcon from '../../../resources/icons/whiteCloseIcon.svg'

/**
 * @param params {object}
 * @returns {JSX.Element}
 * @constructor
 */
export default function NavigationView(params) {

    const routes = params.routes ?? [{pageName: "", pageRef: ""}]

    const [navOpen, setNavOpen] = useState(false)

    function containerizeNavLinks(params) {

        const executeScroll = params.pageRef
            ? () => params.pageRef.current.scrollIntoView({behavior: 'smooth'})
            : () => console.log("no reference found for " + params.pageName)

        return (
            <button onClick={executeScroll} className={"font-recursive text-white-900 bg-black-900 py-3 my-1 w-full md:px-7 md:mx-3 md:w-fit rounded-3xl flex items-center justify-center"}>
                <p>{params.pageName}</p>
            </button>
        )
    }

    function showNav() {
        setNavOpen(true)
    }
    function hideNav() {
        setNavOpen(false)
    }

    // noinspection JSValidateTypes
    return (
        <nav className={"fixed w-full z-50 top-0"}>
            <div className={(navOpen ? "" : "hidden") + " absolute w-screen"}>
                <div className={"grid grid-cols-3 bg-black-500 pl-12 py-10"}>
                    <div id={"header"} className={"col-start-1 col-end-3 md:ml-16 md:flex"}>

                        <a href={"/"}>
                            <div className={"text-white-900 bg-black-900 py-3 my-1 w-full md:px-7 md:mx-3 md:w-fit rounded-3xl flex items-center justify-center"}>
                                <button className={"font-recursive"}>
                                    Home
                                </button>
                            </div>
                        </a>

                        {routes.map(containerizeNavLinks)}
                    </div>
                    <div className={"float-right right-10 md:right-16 absolute"}>
                        <button
                            className={"p-5 rounded-full bg-no-repeat bg-center bg-auto"}
                            onClick={hideNav}
                            style={{ backgroundImage: `url(` + navCloseIcon + `)` }} />
                    </div>
                </div>
            </div>
            <div className={"float-right right-10 md:right-16 mt-10 absolute"}>
                <div className={"bg-white-900 rounded-full " + (!navOpen ? "" : "hidden")}>
                    <button
                        className={"p-5 rounded-full bg-no-repeat bg-center bg-auto"}
                        onClick={showNav}
                        style={{ backgroundImage: `url(` + BurgerIcon + `)` }}
                    />
                </div>
            </div>
        </nav>
    )
}
import React from "react"

export default function WelcomeBlock(props) {
    const header = props.header ?? ""
    const content = props.content ?? ""
    const icon = props.icon

    return (
        <div id={"welcomeBlock"}  className={"block-padding"}>
            <h2 className={"text-primary header-m"}>{header}</h2>
            <p className={"text-white-900 content-m"}>{content}</p>
            <img className={"mx-auto mt-14"} src={icon} alt={""} />
        </div>
    )
}
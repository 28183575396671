import React from "react"

import TestBackground from '../../../../resources/imgs/pinkPoolHeadImg.jpg'
import {bgImgStyle} from "../../../../utils";

export default function ReelCrafterPlayer(props) {

    const title = props.title ?? ""
    const src = props.src ?? ""
    const background = props.bg ?? TestBackground

    return (
        <div className={"relative z-0"}>
            <div className={"absolute left-0 top-0 h-full w-full -z-10 bg-cover bg-right"}
                 style={bgImgStyle(background)} />

            <div className={"z-50 bg-gradient-to-r from-black-300"}>
                <h3 className={"content-m text-primary"}>{title}</h3>
                <iframe
                    title={title}
                    width="100%"
                    height="450"
                    src={src}
                />
            </div>
        </div>
    )
}
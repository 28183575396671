import React from "react"

export default function TrackPreviewBlock(props) {
    const header = props.header ?? "our tracks"
    const players = props.players ?? []

    function containerizePlayer(player) {
        return (
            <div className={"backdrop-blur-lg bg-cover shadow-xl"}>
                {player}
            </div>
        )
    }

    return (
        <div id={"trackPreviewBlock"} className={"block-outer-margin block-padding"}>
            <h2 className={"text-secondary mb-14 header-m"}>{header}</h2>
            <div className={"grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-14"}>
                {players.map(containerizePlayer)}
            </div>
        </div>
    )
}
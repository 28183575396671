import React from "react"

export default function LogoBanner(props) {
    const logos = props.logos ?? []

    function printLogo(logoSrc) {
        return (
            <div className={"flex items-center justify-center"}>
                <img className={"h-8 m-5"}
                     src={logoSrc}
                     alt={logoSrc}
                     style={{filter: "grayscale(100%)"}}
                />
            </div>
        )
    }

    return (
        <>
            <div className={"py-2 bg-white-900"}>
                <div className={"w-full text-center text-gray-500 flow masonry-md md:columns-8"}>
                    {logos.map(printLogo)}
                </div>
            </div>
        </>
    )
}
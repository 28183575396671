import React from "react";
import NavigationView from "../view/NavigationView";

/**
 * @param params{object} routes: [{pageName: "name", pagePath: "path"}]
 * @returns {JSX.Element}
 * @constructor
 */
export default function NavigationController(params) {
    const routes = params.routes ?? [{}]

    return(
        <NavigationView
            routes={routes}
        />
    )
}
import React from "react";
import HomepageView from "../view/HomepageView";
import HeadImgBlock from "../../components/HeadImgBlock";
import WelcomeBlock from "../../components/WelcomeBlock";
import TrackPreviewBlock from "../../components/TracksPreviewBlock";
import StudioPreviewBlock from "../../components/StudioPreviewBlock";
import ContactHomeBlock from "../../components/ContactHomeBlock";
import VisionBlock from "../../components/VisionBlock";

import PinkPoolImg from "../../../../resources/imgs/pinkPoolHeadImg.jpg"
import PinkPoolFlamingo from "../../../../resources/icons/poolFlamingoIcon.svg"
import VisionImg from "../../../../resources/imgs/vision.JPG"

import "../../../../consts";

import {
    ContactData,
    ExampleHomeTrackBlocks,
    HomeWelcomeBlockContent,
    SendMailApiUrl,
    VisionBlockContent,
    Clients,
    GetSlideshowUrl
} from "../../../../consts";

export default function HomepageController(props) {

    const pageRefs = props.pageRefs ?? {}

    const blocksToRender = []

    blocksToRender.push(<HeadImgBlock img={PinkPoolImg}
                                      logos={Clients}
                                      buttonRef={pageRefs["welcomeBlock"]}
    />)

    blocksToRender.push(<WelcomeBlock header={"this is pinkpoolmusic"}
                                      content={HomeWelcomeBlockContent}
                                      icon={PinkPoolFlamingo}
                                      pageRef={pageRefs["welcomeBlock"]}
    />)

    blocksToRender.push(<TrackPreviewBlock players={ExampleHomeTrackBlocks}
                                           pageRef={pageRefs["trackPreviewBlock"]}
    />)

    blocksToRender.push(<VisionBlock content={VisionBlockContent}
                                     img={VisionImg}
                                     pageRef={pageRefs["visionBlock"]}
    />)

    blocksToRender.push(<StudioPreviewBlock pageRef={pageRefs["studioPreviewBlock"]}
                                            slideshowApiUrl={GetSlideshowUrl}/>)

    blocksToRender.push(<ContactHomeBlock contactData={ContactData}
                                          mailApiUrl={SendMailApiUrl}
                                          pageRef={pageRefs["contactHomeBlock"]}
    />)

    return <HomepageView blocks={blocksToRender} />
}
import React from "react"
import {getCurrentRootUrl} from "./utils"
import ReelCrafterPlayer from "./pages/homepage/components/subcomponent/ReelCrafterPlayer";

import Mediashop from "./resources/logos/mediashop.png"
import GeniusTV from "./resources/logos/genius_tv.png"
import CBS from "./resources/logos/cbs.png"
import Lifetime from "./resources/logos/lifetime.png"
import TBS from "./resources/logos/tbs.png"
import BBC from "./resources/logos/bbc.png"
import ARD from "./resources/logos/ard.png"
import ORF from "./resources/logos/orf.png"

import head_markus from './resources/imgs/headshot_markus.jpg'
import head_christian from './resources/imgs/headshot_christian.jpg'

export const RootUrl = getCurrentRootUrl()

export const HomeWelcomeBlockContent = "Founded by veterans in the production music industry - exclusively providing a fresh and tailored music library for broadcasting, DRTV and multimedia channels."

export const VisionBlockContent = "With the right engaging and emotional connecting music, sales on advertising platforms can strive. Because of our long track record and music producer network, any custom wishes and adaptions to the music library can be done very easily and fast, providing you with tailored sonic branding to your business."

export const ExampleHomeTrackBlocks = [
    (<ReelCrafterPlayer title={"Driving Rock"}          src={"https://play.reelcrafter.com/embed/c50ef3cb-e3cc-4d66-8136-b1ce6f9cff4e"} />),
    (<ReelCrafterPlayer title={"Exciting Pop"}          src={"https://play.reelcrafter.com/embed/4b116326-79b5-4fa7-9e1a-3ffbfc537253"} />),
    (<ReelCrafterPlayer title={"Building Cinematic"}    src={"https://play.reelcrafter.com/embed/ca31d809-de04-4fe2-b064-23c6f9c1e1c1"} />),
    (<ReelCrafterPlayer title={"Light Breeze"}          src={"https://play.reelcrafter.com/embed/d44c517a-b212-46d8-9ee7-e0c7e818db4f"} />),
]

export const Clients = [
    Mediashop,
    GeniusTV,
    CBS,
    Lifetime,
    TBS,
    BBC,
    ARD,
    ORF
]

export const ContactData = [
    {name: "Christian Brunthaler", title: "Senior Producer / CEO", mail: "christian@pinkpoolmusic.com", tel: "+496644119269", img: head_christian},
    {name: "Markus Zierhofer", title: "Representative", mail: "markus@pinkpoolmusic.com", tel: "+4915209567226", img: head_markus},
]

export const BgColorPattern = [
    "bg-black-500",
    "bg-black-900",
    "bg-black-300",
    "bg-black-900",
]

export const SendMailApiUrl = "https://pinkpoolmusic.com/php/sendMail.php"
export const GetSlideshowUrl = "https://pinkpoolmusic.com/php/getSlideshowImages.php"
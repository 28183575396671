import './styling/App.scss';

import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";

import HomepageController from "./pages/homepage/page/controller/HomepageController";

import NavigationController from "./navigation/nonRoutingNavigation/controller/NavigationController";
import FooterController from "./footer/controller/FooterController";
import {useRef} from "react";
import PrivacyController from "./pages/privacy/page/controller/PrivacyController";
import ImpressumController from "./pages/impressum/page/controller/ImpressumController";

function App() {
    const pageRefs = {
        "welcomeBlock": useRef(),
        "trackPreviewBlock" : useRef(),
        "studioPreviewBlock" : useRef(),
        "visionBlock" : useRef(),
        "contactHomeBlock" : useRef(),
    }

    const routes = [
        {pageName: "Our Tracks", pageRef: pageRefs["trackPreviewBlock"]},
        {pageName: "Our Vision", pageRef: pageRefs["visionBlock"]},
        {pageName: "Our Studio", pageRef: pageRefs["studioPreviewBlock"]},
        {pageName: "Contact",    pageRef: pageRefs["contactHomeBlock"]},
    ]

    /*function containerizeRoutes(params) {
        return <Route path={params.pagePath} element={params.element} />
    }*/

    const pages = {
        "" : <HomepageController pageRefs={pageRefs} />,
        "privacy" : <PrivacyController />,
        "impressum" : <ImpressumController />,
    }

    function SwitchPageComponent() {
        const search = useLocation().search;
        const page = new URLSearchParams(search).get('page') ?? "";

        return (pages[page])
    }

    return (
        <div className="App overflow-x-hidden h-screen">
            <BrowserRouter>

                <NavigationController routes={routes} />

                <Routes>
                    <Route path={"/"} element={<SwitchPageComponent />} />
                </Routes>

                {/*<div>
                    <Routes>
                        {routes.map(containerizeRoutes)}
                    </Routes>
                </div>*/}

                <FooterController />

            </BrowserRouter>
        </div>
    );
}

export default App;
